<template>
  <v-container fluid>
    <v-card>
      <v-toolbar
        color="secondary"
        flat
      >
        <v-toolbar-title>Outgoing Payments</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="accent"
          small
          to="/banking/new-outgoing-payment"
        >
          <v-icon
            left
            dark
          >mdi-plus</v-icon>New Outgoing Payment
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="search"
              append-icon="mdi-search"
              label="Search"
              single-line
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="12"
          >
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="docData"
              :search="search"
            >
              <!-- start of posting date template -->
              <template v-slot:item.DocDate="{ item }">{{ item.DocDate | moment("Do MMM YYYY")}}</template>
              <!-- end of posting date template -->

              <!-- start of delivery date template -->
              <template v-slot:item.DocDueDate="{ item }">{{ item.DocDueDate | moment("Do MMM YYYY")}}</template>
              <!-- end of delivery date template -->

              <!-- start of document date template -->
              <template v-slot:item.TaxDate="{ item }">{{ item.TaxDate | moment("Do MMM YYYY")}}</template>
              <!-- end of document date template -->

              <!-- action template -->
              <template v-slot:item.DocNum="{ item }">
                <v-btn
                  icon
                  :to="`/banking/outgoing-payment/${item.id}`"
                >
                  {{ item.DocNum }}
                </v-btn>
              </template>
              <!-- end of action template -->
            </v-data-table>
          </v-col>
        </v-row>
        <snackbar ref="snackbar"></snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    docData: [],
    headers: [
      { text: "Doc No.", value: "DocNum" },
      { text: "Posting Date", value: "DocDate" },
      { text: "Due Date", value: "DocDueDate" },
      { text: "Document Date", value: "TaxDate" },
      { text: "Document Total", value: "DocTotal" },
    ],
  }),
  methods: {
    getDocData() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/outgoing_payment`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.docData = res.ResponseData;
            self.loading = false;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    localStorage.removeItem("BaseDocID");
    localStorage.removeItem("BaseDocTotal");
    this.getDocData();
  },
};
</script>